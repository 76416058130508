.hide_input {
  visibility: hidden;
  line-height: 0;
  height: 0 !important;
}


.uplaod-input-view {
  position: relative;
  padding-top: 10px;
  width: 150px;
  margin: 0 auto;


  .edit-img {
    position: absolute;
    top: 10%;
    right: 0;
    background: #fff;
    border-radius: 100%;
    padding: 10px;
    border: 1px solid #e1eaf1;
    cursor: pointer;
  }
  img {
    width: 150px;
    height: 150px;
    border-radius: 20px;
    border: 1px solid #ccc;
    padding: 2px;
    border-radius: 100%;
  }
}
