.header {
  background: #0c425d;
  color: #fff;
  padding-top: 7px;
  padding-bottom: 7px;
  border-bottom: 1px solid #ccc;
}
.menu-icon {
  float: left;
  margin-top: 5px;
  padding-right: 20px;
  cursor: pointer;
}
.user-logo {
  border-radius: 100%;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.footer {
  background: #fff;
  height: 30px;
  padding-top: 5px;
  font-size: 12px;
}

.main-content {
  background: #eeeeee;
  min-height: 100vh;
  height: auto;
  width: 100%;
}
