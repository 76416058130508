.btn-info {
  background: #17a2b8 !important;
  color: #fff !important;
  padding: 0 11px !important;
  border: none !important;
  box-shadow: none !important;
}

.btn-label {
  position: relative;
  left: -12px;
  display: inline-block;
  padding: 5px 6px;
  background: rgba(0, 0, 0, 0.15);
  border-radius: 3px 0 0 3px;
  .material-icons {
    vertical-align: bottom;
  }
}

.mat-mdc-unelevated-button.mat-primary,
.mat-mdc-raised-button.mat-primary,
.mat-mdc-fab.mat-primary,
.mat-mdc-mini-fab.mat-primary {
  background-color: #0b435f !important;
  color: #fff !important;
}

.mat-mdc-button.mat-accent,
.mat-mdc-icon-button.mat-accent,
.mat-mdc-outlined-button.mat-accent {
  color: #198754 !important;
  border: 1px solid #198754 !important;
}

.mat-mdc-button.mat-warn,
.mat-mdc-icon-button.mat-warn,
.mat-mdc-outlined-button.mat-warn {
  color: #f44336 !important;
  border: 1px solid #f44336 !important;
}
.btn {
  outline: none !important;
  box-shadow: none !important;
  font-size: 14px !important;
}

.btn-success {
  color: #fff;
  background-color: #28a745 !important;
  border-color: #28a745 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

.btn-primary {
  background-color: #0b435f !important;
  border-color: #0b435f !important;
  color: #fff !important;
}
