// Breadcrumb
/*** breadcrumb container ***/
.bcca-breadcrumb {
  display: flex;
  flex-direction: row-reverse;
  flex-shrink: 0;
  width: fit-content;
  position: relative;
  border-radius: 4px;

}

/*** breadcrumb items ***/
.bcca-breadcrumb-item {
  transition: all 0.2s ease-in-out;
  height: 29px;
  background: #fff;
  box-shadow: 0px 0px 18px -2px #d9d9d9;
  line-height: 30px;
  padding-left: 30px;
  padding-right: 10px;
  font-size: 13px !important;
  font-weight: 500 !important;
  color: rgba(74, 74, 74, 0.8);
  position: relative;
  cursor: pointer;
  float: left;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.bcca-breadcrumb-item:after {
  transition: all ease-in-out 0.2s;
  content: "";
  position: absolute;
  left: calc(100% - 10px);
  top: 1px;
  z-index: 1;
  width: 0;
  height: 0;
  border: 12px solid #ffffff;
  border-left-color: transparent;
  border-bottom-color: transparent;
  box-shadow: 0px 0px 0 0px #d9d9d9, 5px -5px 10px -4px #d9d9d9;
  transform: rotate(45deg);
  margin-left: -4px;
}

.bcca-breadcrumb-item:last-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  padding-left: 10px;
}

.bcca-breadcrumb-item:first-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: rgba(23, 165, 98, 0.06);
}

.bcca-breadcrumb-item:first-child:after {
  content: "";
  display: none;
}

.bcca-breadcrumb-item i {
  margin-left: 5px;
  color: rgba(0, 0, 0, 0.2);
}

/*** hover breadcrumbs ***/
.bcca-breadcrumb-item:hover {
  background-color: #f9f9f9;
}

.bcca-breadcrumb-item:hover:after {
  border: 12px solid #f9f9f9;
  border-left-color: transparent;
  border-bottom-color: transparent;
}

.bcca-breadcrumb-item:first-child:hover {
  background-color: rgba(23, 165, 98, 0.15);
}
.bread-crumb{
    background: #fff;
    border-bottom: 1px solid #ccc;
}

