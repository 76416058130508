@font-face {
  font-family: "SFProDisplayRegular";
  src: url("~src/assets/fonts/sfprodisplayregular/SFProDisplayRegular.eot");
  src: url("~src/assets/fonts/sfprodisplayregular/SFProDisplayRegular.eot")
      format("embedded-opentype"),
    url("~src/assets/fonts/sfprodisplayregular/SFProDisplayRegular.woff2")
      format("woff2"),
    url("~src/assets/fonts/sfprodisplayregular/SFProDisplayRegular.woff")
      format("woff"),
    url("~src/assets/fonts/sfprodisplayregular/SFProDisplayRegular.ttf")
      format("truetype"),
    url("~src/assets/fonts/sfprodisplayregular/SFProDisplayRegular.svg#SFProDisplayRegular")
      format("svg");
}

@font-face {
  font-family: "GESSTextMedium";
  src: url("~src/assets/fonts/gess/GESSTextMedium.eot");
  src: url("~src/assets/fonts/gess/GESSTextMedium.eot")
      format("embedded-opentype"),
    url("~src/assets/fonts/gess/GESSTextMedium.woff2") format("woff2"),
    url("~src/assets/fonts/gess/GESSTextMedium.woff") format("woff"),
    url("~src/assets/fonts/gess/GESSTextMedium.ttf") format("truetype"),
    url("~src/assets/fonts/gess/GESSTextMedium.svg#GESSTextMedium")
      format("svg");
}

@font-face {
  font-family: "dinnextltw23medium";
  src: url("~src/assets/fonts/dinn/dinnextltw23medium.eot");
  src: url("~src/assets/fonts/dinn/dinnextltw23medium.eot")
      format("embedded-opentype"),
    url("~src/assets/fonts/dinn/dinnextltw23medium.woff2") format("woff2"),
    url("~src/assets/fonts/dinn/dinnextltw23medium.woff") format("woff"),
    url("~src/assets/fonts/dinn/dinnextltw23medium.ttf") format("truetype"),
    url("~src/assets/fonts/dinn/dinnextltw23medium.svg#dinnextltw23medium")
      format("svg");
}

.carousel-control-prev,
.carousel-control-next {
  z-index: 999 !important;
}

.picsum-img-wrapper {
  position: relative !important;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
}
.gc-amount {
  position: absolute;
  top: 4%;
  right: 5%;
  direction: rtl;
  font-family: "GESSTextMedium" !important;
  font-size: 24px;
  font-weight: bold;
  span {
    font-size: 12px;
    font-family: "DINNEXTLTW23LIGHT" !important;
  }
}

.align-bottom {
  position: absolute !important;
  right: 5% !important;
  left: auto !important;
  bottom: 7px !important;
  @media screen and (min-width: 460px) {
    bottom: 40px !important;
  }

  .message {
    padding: 17px 10px;
    font-size: 8px;
    direction: rtl;
    max-width: 100%;
    height: 50px;
  }
}

.align-right {
  position: absolute !important;
  right: 4% !important;
  left: auto !important;
  bottom: auto !important;
  top: 20%;

  .message {
    padding: 10px;
    font-size: 8px;
    direction: rtl;
    height: auto;
    max-width: 127px;
    @media screen and (min-width: 460px) {
      max-width: 100%;
    }
  }
}

.align-left {
  position: absolute !important;
  left: 4% !important;
  right: auto !important;
  bottom: auto !important;
  top: 20%;

  .message {
    padding: 10px;
    font-size: 8px;
    direction: rtl;
    height: auto;
    max-width: 127px;
    @media screen and (min-width: 460px) {
      max-width: 100%;
    }
  }
}

.align-top {
  position: absolute !important;
  bottom: auto !important;
  top: 20%;
  .message {
    padding: 10px;
    font-size: 8px;
    direction: rtl;
    height: auto;
  }
}

.gc-theme-preview {
  direction: rtl;
  text-align: right;
  font-family: "dinnextltw23medium";
  .gc-ptitle {
    font-size: 19px;
  }
  .gc-pimg {
    img {
      width: 338px;
    }
  }
  .gcp-amount {
    font-size: 15px;
  }

  .gc-btn {
    .copy {
      padding: 8px 80px;
      border-radius: 5px;
      border: none;
      width: 285px;
      font-size: 16px;
    }

    .send {
      padding: 8px 80px;
      border-radius: 5px;
      border: none;
      width: 285px;
      font-size: 16px;
    }

    .gc-pterm{
      font-size: 15px;
    }

    .gc-download{
      font-size: 16px;
    }
  }
}

.send-dlider-img {
  width: 100%;
}
