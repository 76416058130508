.main-area {
  background: #fff;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 3px;

  width: 100%;
}

.main-area-header {
  background: #f9f9f9;
  padding: 10px;
  font-weight: 700;
  font-size: 15px;
  color: #0b435f;
  border-bottom: 1px solid #eee;
}

.page-title {
  span {
    float: left;
    margin: 5px;
  }
  .material-icons {
    margin-top: 1px;
  }
}

.page-title-text {
  padding-top: 3px;
}

.badge{
  font-weight: normal !important;
  font-size: 13px !important;
}

table {
  width: 100%;
}
.main-area-table{
  padding: 20px;
  min-height: 500px;
}

.action {
  li {
    list-style: none;
    color: #0b435f;
    cursor: pointer;
    text-decoration: none;
    padding-bottom: 5px;
    display: inline-block;
    margin-right: 20px;
    &:hover {
      text-decoration: underline;
    }
  }

  .delete {
    color: #fe5c60;
  }

  .edit {
    color: #008000;
  }
}

