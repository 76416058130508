.preview-area {
  position: relative;
}


.preview-amount {
  position: absolute;
  top: 4%;
  right: 5%;
  direction: rtl;
  font-family: "GESSTextMedium" !important;
  font-size: 24px;
  font-weight: bold;
  span {
    font-size: 12px;
    font-family: "DINNEXTLTW23LIGHT" !important;
  }
}



.preview-align-bottom {
  position: absolute !important;
  right: 5% !important;
  left: auto !important;
  bottom: 7px !important;
  @media screen and (min-width: 460px) {
    bottom: 40px !important;
  }

  .message {
    padding: 17px 10px;
    font-size: 8px;
    direction: rtl;
    max-width: 100%;
    height: 50px;
  }
}

.preview-align-right {
  position: absolute !important;
  right: 4% !important;
  left: auto !important;
  bottom: auto !important;
  top: 20%;

  .message {
    padding: 10px;
    font-size: 8px;
    direction: rtl;
    height: auto;
    max-width: 127px;
    @media screen and (min-width: 460px) {
      max-width: 100%;
    }
  }
}

.preview-align-left{
  position: absolute !important;
  left: 4% !important;
  right: auto !important;
  bottom: auto !important;
  top: 20%;

  .message {
    padding: 10px;
    font-size: 8px;
    direction: rtl;
    height: auto;
    max-width: 127px;
    @media screen and (min-width: 460px) {
      max-width: 100%;
    }
  }
}

.preview-align-top {
  position: absolute !important;
  bottom: auto !important;
  top: 20%;
  right: 22%;
  .message {
    padding: 10px;
    font-size: 8px;
    direction: rtl;
    height: auto;
  }
}

.preview-img {
  width: 100%;
}

//  media query for small screens

@media (max-width: 575.98px) {
  .col-in-mobile{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
}