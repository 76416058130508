@font-face {
  font-family: "SFProDisplayRegular";
  src: url("~src/assets/fonts/sfprodisplayregular/SFProDisplayRegular.eot");
  src: url("~src/assets/fonts/sfprodisplayregular/SFProDisplayRegular.eot")
      format("embedded-opentype"),
    url("~src/assets/fonts/sfprodisplayregular/SFProDisplayRegular.woff2")
      format("woff2"),
    url("~src/assets/fonts/sfprodisplayregular/SFProDisplayRegular.woff")
      format("woff"),
    url("~src/assets/fonts/sfprodisplayregular/SFProDisplayRegular.ttf")
      format("truetype"),
    url("~src/assets/fonts/sfprodisplayregular/SFProDisplayRegular.svg#SFProDisplayRegular")
      format("svg");
}
