.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999 !important;
    background-color: rgba(0, 0, 0, 0.7);
  }
  mat-progress-spinner {
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
  }

  .mat-mdc-progress-spinner circle, .mat-mdc-progress-spinner circle {
    stroke: #0b435f;
    }
