.example-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: #eee;
}

.example-container2 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
}
.example-sidenav-content {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.example-sidenav {
  background: #0c425d;
  color: #fff;
}

.mat-drawer-content {
  height: calc(100% - 50px);
}

.sidebar-menu {
  background: #0c425d;
  color: #fff;
  li {
    display: block;
  }
  .child {
    padding: 0 24px 12px;
    .material-icons {
      float: left;
      margin-top: -3px;
      margin-right: 6px;
    }
  }
}

.mat-expansion-panel {
  background: #0c425d;
  color: #fff;
}

.mat-accordion .mat-expansion-panel:last-of-type {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.mat-accordion .mat-expansion-panel:first-of-type {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.mat-expansion-panel-header-title {
  color: #fff;
  .material-icons {
    float: left;
    margin-top: -3px;
    margin-right: 6px;
  }
}

.mat-expansion-panel ul li {
  .material-icons {
    margin-right: 10px;
  }
}

.mat-expanded {
  background: #0d394f;
}

.mat-expanded {
  .mat-expansion-panel-header {
    background: #0d394f;
  }
}

.sidebar-href {
  color: #fff !important;
  display: flex;
  justify-content: flex-start;
  margin-top: 14px;
  margin-bottom: 6px;
  &:hover {
    text-decoration: none !important;
    color: #54d5ff !important;
  }
}

.crt {
  color: #54d5ff;
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: #fff;
}

.mat-expansion-panel {
  box-shadow: none !important;
}

.mat-expansion-panel-header.mat-expanded {
  height: 50px !important;
}

.mat-expansion-panel-header {
  height: 50px !important;
}

.mat-expansion-panel-spacing {
  margin: 0 !important;
}

.mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover{
  background-color: #0c425d  !important;
}