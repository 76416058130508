a {
  color: #0b435f !important;
  text-decoration: none !important;
  &:hover {
    text-decoration: underline !important;
  }
}

.multiselect-dropdown .dropdown-btn .selected-item-container .selected-item {
  border: 1px solid #0b435f !important;
  background: #0b435f !important;
  max-width: inherit !important;
  border-radius: 3px !important;
  a {
    color: #fff !important;
  }
}

.label-info {
  background: #0b435f;
  color: #f1faf6;
  border-radius: 5px;
  float: left;
  padding: 2px 4px;
  margin: 2px;
  inline-size: max-content;
}

.section-title {
  color: #8f8f8f;
  font-size: 12px;
}

.unedited-data {
  border-bottom: 1px solid #eee;
}


.multiselect-dropdown .dropdown-btn{
  border: none !important;
  border-bottom: 1px solid #adadad !important;
  border-radius :0 !important
}
.small-container {
  max-width: 768px !important;
}

.form-section-title {
  padding-top: 10px;
  padding-bottom: 10px;
  background: #f9f9f9;
  border: 1px solid #eee;
  padding-left: 10px;
  border-radius: 5px;
  color: #666;
}

.show-message-icon{
  float: left !important;
  margin-top: -2px;
  margin-right: 5px;
}

.form-note-color {
  color: #0b435f !important;
}


.mat-mdc-input-element:read-only {
  color: #9e9e9e;
}
.mat-mdc-form-field-focus-overlay {
  background-color: #fff
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: #fff
}