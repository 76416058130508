// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$nugttah-admin-primary: mat.define-palette(mat.$indigo-palette);
$nugttah-admin-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$nugttah-admin-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$nugttah-admin-theme: mat.define-light-theme((color: (primary: $nugttah-admin-primary,
        accent: $nugttah-admin-accent,
        warn: $nugttah-admin-warn,
      ),
    ));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($nugttah-admin-theme);
@import url("https://fonts.googleapis.com/css2?family=Patrick+Hand&family=Roboto:ital,wght@0,700;1,300;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,700;1,300;1,400&display=swap");
/* You can add global styles to this file, and also import other style files */
@import "./assets/scss/layout.scss";
@import "./assets/scss/_sidebar.scss";
@import "./assets/scss/_breadcrumb.scss";
@import "./assets/scss/_listscreen.scss";
@import "./assets/scss/_colors.scss";
@import "./assets/scss/_buttons.scss";
@import "./assets/scss/_table.scss";
@import "./assets/scss/_global.scss";
@import "./assets/scss/_snackbar.scss";
@import "./assets/scss/_loading.scss";
@import "./assets/scss/_fonts.scss";
@import "./assets/scss/_dialog.scss";
@import "./assets/scss/_fileupload.scss";
@import "./assets/scss/_giftcard.scss";
@import "./assets/scss/_giftcardcarousel.scss";
@import "~viewerjs/dist/viewer.css";
@import "~@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
}

* {
  outline: none !important;
}

ul {
  padding: 0 !important;
  margin: 0 !important;
}

app-login {
  width: 100%;
}

.login-website {
  margin-top: 120px;
}

.imgborder {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
  box-shadow: 0px 6px 13px #ccc;
  margin-top: 5px;
  margin-bottom: 5px;
}

.status-list {
  width: 100%;
}

.signature {
  font-size: 30px !important;
}

.custom-paginator-container {
  justify-content: flex-end;
}

.custom-paginator-counter {
  white-space: nowrap;
  margin: 0px 4px 0px 10px !important;
}

.custom-paginator-page {
  border-radius: 4px;
  outline: none;
  border: none;
  margin: 0.4em;
  font-size: 16px;
  min-width: 30px;
  width: auto;
  min-height: 30px;
  max-height: 30px;
  padding: 1px 6px;
  color: #343434;
  font-weight: 500;

}

.custom-paginator-page-enabled {
  color: #333333;
  background: transparent;
  cursor: pointer;

  &:hover {
    background-color: #dfe3e6;
  }
}

.custom-paginator-page-disabled {
  background-color: #09425E;
  color: #fff;
  border-radius: 50%;
}

.custom-paginator-arrow-disabled {
  color: #dfe3e6;
}

.custom-paginator-arrow-enabled {
  color: #999999;
}

.custom-paginator-page-enabled:hover {
  background-color: #dfe3e6;
  border-radius: 50%;
}