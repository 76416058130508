thead {
  border-top: 1px solid #dee2e6 !important;
  border-bottom: 2px solid #dee2e6 !important;
  background: #f9f9f9 !important;
  box-sizing: border-box;
  border-radius: 4px 4px 0 0;
}
th {
  color: #212529 !important;
  font-weight: bold !important;
  font-size: 13px !important;
}

table {
  color: #212529 !important;
}
.mat-mdc-header-cell {
  color: #212529 !important;
 font-weight: 700 !important;
  font-size: 13px !important;
  padding-top: 16px;
}

.mat-mdc-cell {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  height: 87px;
}

.custom-td{
  text-align: center;
  padding-right: 0 !important;
}



